import moment from 'moment';

import React from 'react';
import styled from 'styled-components';

import { QuestionCircleOutlined } from '@ant-design/icons';
import Tag from 'antd/lib/tag';

import {
  fieldName,
} from '../common';

import tests from '../../helpers/tests';
import getFilterWithCol from '../../helpers/getFilterWithCol';

import { Tooltip } from '../../components/Popover';

import {
  anyoneIsAnnotating,
  viewerIsAnnotating,
  viewerIsNotAnnotating,
  someoneElseIsAnnotating,
  viewerIsNotAdminNorContentManagerAndViewerIsNotAnnotatingOrViewerIsAnnotating,
} from '../visuals/fields';

import Types from '../../common/modules/types';

const fieldAnnotations = ['FieldVideoSession', {
  name: 'annotations',
}];

const ShortcutItem = styled.p`
  margin-top: 5px;
  margin-bottom: 0px;
  > .ant-tag {
    border-radius: 5px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  fieldName,

  // getFilterWithCol(['FieldText', {
  //   name: 'shopperId',
  //   label: 'Shopper ID',
  // }], 8),

  // getFilterWithCol(['FieldText', {
  //   name: 'storeName',
  //   label: 'Store Name',
  // }], 8),

  // getFilterWithCol(['FieldDatePicker', {
  //   name: 'sessionDate',
  //   label: 'Session Date',
  //   prepareValueForInput: value => moment(value),
  // }], 8),

  getFilterWithCol(['FieldUploadRefsHorizontal', {
    name: 'receipt',
    label: 'Receipt',
    maxCount: 1,
    accept: 'image/png,image/jpeg,image/jpg',
    listType: 'picture',
    transformations: [
      ['GM', {
        command: 'compress',
        quality: 90,
        maxWidth: 1200,
        maxHeight: 1200,
      }],
    ],
    // disabled: [activityNotCreateNorStatusNew],
    // hidden: [activityNotCreateNorStatusNew],
  }], 24),

  ['FieldTitle', {
    title: (
      <>
        Annotations
        &nbsp;
        <Tooltip
          title={(
            <strong>
              Keyboard Shortcuts
            </strong>
          )}
          content={(
            <>
              <ShortcutItem>
                <Tag><code>Space</code></Tag>
                Play / Pause
              </ShortcutItem>
              <ShortcutItem>
                <Tag><code>Arrow Left / Right</code></Tag>
                Seek Back / Forward
              </ShortcutItem>
              <ShortcutItem>
                <Tag>0</Tag>
                <Tag>1</Tag>
                <Tag>2</Tag>
                <Tag>4</Tag>
                Playback Speed (0.5, 1, 2, 4)
              </ShortcutItem>
              <ShortcutItem>
                <Tag><code>n</code></Tag>
                New Item
              </ShortcutItem>
              <ShortcutItem>
                <Tag><code>d</code></Tag>
                Duplicate Last Item
              </ShortcutItem>
              {
                Types
                .VIDEO_SESSION_ANNOTATION_EVENT_TYPE_ITEMS
                .map((item) => (
                  <ShortcutItem key={item.id}>
                    <Tag>
                      <code>
                        {item.shortcut}
                      </code>
                    </Tag>
                    {item.label}
                  </ShortcutItem>
                ))
              }
            </>
          )}
        >
          <QuestionCircleOutlined
            style={{ fontSize: '80%' }}
          />
        </Tooltip>
      </>
    ),
    skip: [tests.viewerIsNotAdminNorContentManager],
  }],

  ['ActionWithRequest', {
    name: 'actionStartAnnotating',
    title: 'Start Annotating',
    // eslint-disable-next-line no-unused-vars
    getActionTitle: (props, action) => {
      if (someoneElseIsAnnotating(props)) {
        return (
          <>
            Take Over Annotation Session
            <span
              style={{
                display: 'block',
                fontSize: '80%',
                marginTop: '-2px',
              }}
            >
              {`from ${
                props.data.annotationsSession?.user?.name || 'another user'
              }`}
            </span>
          </>
        );
      }
      return 'Start Annotating';
    },
    skip: [
      (props) => {
        const { role } = props.viewer || {};
        const { status } = props.data || {};
        if (
          role === 'ADMIN'
          && (
            viewerIsNotAnnotating(props)
            || (
              viewerIsAnnotating(props)
              && status === 'COMPLETE'
            )
          )
        ) {
          return false;
        }
        if (
          role === 'CONTENT_MANAGER'
          && viewerIsNotAnnotating(props)
          && ['NEW', 'PROCESSING'].includes(status)
        ) {
          return false;
        }
        return true;
      },
    ],
    disabled: [],
    getRequestConfig: (props) => ({
      url: `videosessions/${props.data._id}/actions/start-annotating`,
      data: {},
      method: 'POST',
    }),
    handleSuccess: (data, props) => props.reload(),
    type: 'default',
    style: {
      width: '100%',
      height: 'auto',
      minHeight: '32px',
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    skipRules: true,
  }],

  fieldAnnotations,
];
