import camelCase from 'lodash/camelCase';

import Types from '../common/modules/types';

const capitalizeFirstLetter = string => `${
  string[0].toUpperCase()
}${
  string.slice(1)
}`;

const tests = {
  ...Types.USER_ROLE.reduce(
    (agr, role) => {
      ['===', '!=='].forEach(op => ['user', 'viewer'].forEach((type) => {
        agr[`${
          type
        }${
          op === '===' ? 'Is' : 'IsNot'
        }${
          capitalizeFirstLetter(camelCase(role))
        }`] = (
            type === 'user'
          ? [
              'condition.testFieldValue',
              'role',
              op,
              role,
            ]
          : [
              'condition.checkViewer',
              'role',
              op,
              role,
            ]
        );
      }));
      return agr;
    },
    {},
  ),

  isNotCreateActivity: ({ activity }) => activity !== 'create',
  isNotEditActivity: ({ activity }) => activity !== 'edit',
};

tests.viewerIsNotAdminWithEmail = (emails = []) => props => (
      props.viewer?.role !== 'ADMIN'
  || !emails.includes(props.viewer?.email)
);

tests.viewerIsNotAdminWithEmail.THEHIVERS_EMAILS = [
  'nikolakanacki@thehive.rs',
  'markoradak@thehive.rs',
  'ivanprtljaga@thehive.rs',
];

tests.viewerIsNotThehiveRsAdmin = tests.viewerIsNotAdminWithEmail([
  ...tests.viewerIsNotAdminWithEmail.THEHIVERS_EMAILS,
]);

[
  /* eslint-disable max-len */
  ['CONTENT_MANAGER', 'ViewerContentManagerWhichCanManageVisuals', 'manageVisuals'],
  ['CONTENT_MANAGER', 'ViewerContentManagerWhichCanManagePrivateVisuals', 'managePrivateVisuals'],
  ['CONTENT_MANAGER', 'ViewerContentManagerWhichCanManageVideoSessions', 'manageVideoSessions'],
  ['CUSTOMER', 'ViewerCustomerWhichCanManageOrganization', 'manageOrganization'],
  // ['CUSTOMER', 'ViewerCustomerWhichCanManageLibrary', 'manageLibrary'],
  ['CUSTOMER', 'ViewerCustomerWhichCanManageVisuals', 'manageVisuals'],
  ['CUSTOMER', 'ViewerCustomerWhichCanReadVisuals', 'readVisuals'],
  /* eslint-enable max-len */
].forEach(([role, name, permission]) => {
  // eslint-disable-next-line max-len
  tests[`is${name}`] = props => (
    props.viewer?.role === role
    && props.viewer?.permissions?.[permission]
  );
  tests[`isNot${name}`] = props => (
    props.viewer?.role !== role
    || !props.viewer?.permissions?.[permission]
  );
});

tests.viewerIsAdminOrContentManager = ['condition.or', [
  tests.viewerIsAdmin,
  tests.viewerIsContentManager,
]];

tests.viewerIsAdminOrContentManagerWhichCanManageVisuals = ['condition.or', [
  tests.viewerIsAdmin,
  tests.isViewerContentManagerWhichCanManageVisuals,
]];

tests.viewerIsAdminOrContentManagerWhichCanManageVideoSessions = [
  'condition.or', [
    tests.viewerIsAdmin,
    tests.isViewerContentManagerWhichCanManageVideoSessions,
  ],
];

tests.viewerIsNotAdminNorContentManager = ['condition.and', [
  tests.viewerIsNotAdmin,
  tests.viewerIsNotContentManager,
]];

tests.viewerIsNotAdminNorContentManagerWhichCanManageVisuals = [
  'condition.and', [
    tests.viewerIsNotAdmin,
    tests.isNotViewerContentManagerWhichCanManageVisuals,
  ],
];

tests.viewerIsNotAdminNorContentManagerWhichCanManageVideoSessions = [
  'condition.not',
  tests.viewerIsAdminOrContentManagerWhichCanManageVideoSessions,
];

// eslint-disable-next-line max-len
tests.viewerIsAdminOrContentManagerWhichCanManagePrivateVisuals = ['condition.or', [
  tests.viewerIsAdmin,
  tests.isViewerContentManagerWhichCanManagePrivateVisuals,
]];

// eslint-disable-next-line max-len
tests.viewerIsNotAdminNorContentManagerWhichCanManagePrivateVisuals = ['condition.and', [
  tests.viewerIsNotAdmin,
  tests.isNotViewerContentManagerWhichCanManagePrivateVisuals,
]];

tests.isViewerVisualCreator = props => (
  props.viewer
  && props.data
  && props.data.createdBy === props.viewer._id
);

tests.skipIfUserIsCustomerWhichCanManageOrganization = [
  'condition.testFieldValue',
  'permissions.manageOrganization',
  '===',
  true,
];

tests.skipIfViewerNotAdminNorCustomerWhichCanManageOrganization = [
  'condition.not',
  ['condition.or', [
    tests.viewerIsAdmin,
    tests.isViewerCustomerWhichCanManageOrganization,
  ],
  ],
];

tests.viewerCanEditUserPermissions = ['condition.or', [
  tests.viewerIsAdmin,
  tests.isViewerCustomerWhichCanManageOrganization,
]];

tests.viewerCannotEditUserPermissions = [
  'condition.not',
  tests.viewerCanEditUserPermissions,
];

tests.viewerCannotViewDashboard = [
  'condition.and',
  [
    tests.viewerIsNotAdmin,
    ({ viewer }) => (
      !viewer?.organizationData?.dashboardAccess
      || !viewer?.organizationData?.dashboardPowerBiReports?.length
      || !viewer?.permissions?.readDashboard
    ),
  ],
];

tests.viewerIsNotCustomerOrCannotViewDashboard = [
  'condition.or',
  [
    tests.viewerIsNotCustomer,
    ({ viewer }) => (
      !viewer?.organizationData?.dashboardAccess
      || !viewer?.organizationData?.dashboardPowerBiReports?.length
      || !viewer?.permissions?.readDashboard
    ),
  ],
];

tests.isVisualProcessing = ({ data: { processing } = {} }) => (
  processing === true
);

export default tests;
