import Admin from 'hive-admin';

import clickAnchor from '../../helpers/clickAnchor';

import tests from '../../helpers/tests';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FIELDS_ALL,
} from './fields';

import {
  anyoneIsAnnotating,
  viewerIsAnnotating,
  viewerIsNotAnnotating,
  someoneElseIsAnnotating,
  viewerIsNotAdminNorContentManagerAndViewerIsNotAnnotatingOrViewerIsAnnotating,
} from '../visuals/fields';

const {
  viewerIsNotAdminNorContentManagerWhichCanManageVideoSessions,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Video Sessions',
    icon: 'video-camera',
    path: '/videosessions',
    redirect: [['redirect.unauthorized']],
    skip: [viewerIsNotAdminNorContentManagerWhichCanManageVideoSessions],
    archiveConfig: {
      icon: 'video-camera',
      title: 'Video Sessions',
      label: 'Video Sessions',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
      getCreateButtonSupported: () => false,
    },
    singleConfig: {
      hidden: true,
      alias: '/videosessions',
      backButtonPaths: ['/videosessions'],
      // headerTitle: 'Create Video Session',
      // loadUrl: '/retailers/:id/',
      actions: [
        ['ActionSave', {
          ghost: true,
        }],
        ['ActionDelete', {
          ghost: true,
        }],
        ['Action', {
          name: 'export',
          title: 'Export To CSV',
          icon: 'download',
          ghost: true,
          onClick: (props) => clickAnchor({
            url: `${props.restBaseServer}/videosessionsexport/${
              props.match.params.id
            }`,
            query: {
              access_token: props.client.getAccessToken(),
            },
          }),
        }],
        ['ActionWithRequest', {
          name: 'endAnnotating',
          title: 'Mark As Complete',
          ghost: true,
          skip: [
            props => props.data.status === 'COMPLETE',
          ],
          getRequestConfig: props => ({
            url: `videosessions/${props.data._id}/actions/end-annotating`,
            method: 'POST',
          }),
          handleSuccess: (data, props) => props.reload(),
        }],
      ],
    },
    singleEditConfig: {
      backButtonPaths: ['/videosessions'],
    },
    fields: FIELDS_ALL,
  }])
).pages;
