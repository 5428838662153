import toCamelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

import mongoose from 'mongoose/browser';

import TypesHelpers from './types.helpers';
import TypesVisuals from './types.visuals';

import annotationsRaw from './types.annotations.raw.json';

const Types = {};

const { Types: { ObjectId } } = mongoose;

// const mathRandom = TypesHelpers.createSeededMathRandom(1857);

Object.assign(
  Types,
  TypesHelpers.createValues('VISUAL_ANNOTATION_TYPE', [{
    id: 'SHAPE',
    label: 'Shape',
  }, {
    id: 'TEXT',
    label: 'Text',
  }, {
    id: 'CHOICES',
    label: 'Choices',
  }]),
  TypesHelpers.createValues('VISUAL_ANNOTATION_SHAPE', [{
    id: 'RECT',
    label: 'Rect',
    shapeLabel: 'Box',
    keys: ['x', 'y', 'width', 'height'],
  }, {
    id: 'POINT',
    label: 'Point',
    shapeLabel: 'Point',
    keys: ['x', 'y'],
  }]),
);

Object.assign(
  Types,
  TypesHelpers.createValues(
    'VISUAL_ANNOTATION',
    annotationsRaw
    .map((item) => {
      item.id = item.id || toCamelCase(item.exportKey);
      item.originalId = toCamelCase(item.originalKey);
      item.key = item.id;
      if (!item.label) {
        item.label = item.description || item.exportKey;
      }
      // if (item.contentKey) {
      //   console.log(item.contentKey, item.id);
      // }
      if (item.contentKey) {
        // console.log('item.contentKey:', item.contentKey);
        if (item.contentTypesKey) {
          // console.log('item.contentTypesKey:', item.contentTypesKey);
          const prefix = item.contentTypesKey;
          const typesMap = TypesVisuals[`${prefix}_MAP`];
          const typesItems = TypesVisuals[`${prefix}_ITEMS`];
          item.choices = typesItems.map(choice => ({
            value: choice.annotationValue,
            originalValue: choice.annotationValueOriginal,
            contentValue: choice.id,
            label: choice.annotationLabel,
            exportSchemaId: choice.annotationExportSchemaId,
          }));
          // console.log('item.choices:', item.choices);
          item.getExportValue = (visual) => {
            const typesItem = (
              typesMap[visual[item.contentKey]]
              || typesMap[item.contentTypesDefault || 'OTHER']
            );
            if (
              typesItem?.id === 'OTHER'
              && visual[`${item.contentKey}Label`]?.length
            ) {
              return { answer: visual[`${item.contentKey}Label`] };
            }
            if (typesItem?.annotationValue?.length) {
              return { answer: typesItem?.annotationValue };
            }
            return undefined;
          };
        } else {
          let valueKey = null;
          if (item.type === 'CHOICES') {
            if (item.contentModel) {
              item.getContentChoices = async (_mongoose) => {
                // console.log('item.contentKey:', item.contentKey);
                const Model = mongoose.model(item.contentModel);
                const choicesItems = await Model.find(
                  { kind: { $ne: 'PRIVATE' } },
                );
                return choicesItems.reduce(
                  (agr, choiceItem) => {
                    if (choiceItem.annotationValue) {
                      agr.push({
                        value: choiceItem.annotationValue,
                        label: choiceItem.name,
                        exportSchemaId: `${choiceItem._id}`,
                      });
                    }
                    return agr;
                  },
                  [],
                );
              };
            }
            valueKey = 'annotationValue';
          } else if (item.type === 'TEXT') {
            valueKey = 'name';
          }
          item.getExportValue = (visual) => {
            // console.log('item.contentKey:', item.contentKey);
            const data = visual[`${item.contentKey}Data`];
            const value = data?.[valueKey] || 'other';
            // console.log('value:', value);
            // return ({
            //   value: choice.annotationValue,
            //   originalValue: choice.annotationValueOriginal,
            //   contentValue: choice.id,
            //   label: choice.annotationLabel,
            //   exportSchemaId: choice.annotationExportSchemaId,
            // })
            // console.log(`"${item.contentKey}" value:`, value);
            if (value?.length) {
              return { answer: value };
            }
            return undefined;
          };
        }
      }
      if (item.type === 'SHAPE') {
        item.shapeLabel = Types.VISUAL_ANNOTATION_SHAPE_MAP[
          item.shape
        ].shapeLabel;
      }
      if (item.type === 'CHOICES') {
        item.choicesOriginalMap = item.choices.reduce(
          (agr, choice) => {
            agr[choice.originalValue] = choice;
            return agr;
          },
          {},
        );
        item.choicesMap = item.choices.reduce(
          (agr, choice) => {
            agr[choice.value] = choice;
            return agr;
          },
          {},
        );
      }
      if (isPlainObject(item.required)) {
        item.getIsRequired = (visual) => !!(
            typeof item.required[visual.marketingKind] === 'boolean'
          ? item.required[visual.marketingKind]
          : typeof item.required[visual.marketingType] === 'boolean'
          ? item.required[visual.marketingType]
          : item.required._
        );
      }
      if (isPlainObject(item.skip)) {
        item.getIsSkipped = (visual) => !!(
            typeof item.skip[visual.marketingKind] === 'boolean'
          ? item.skip[visual.marketingKind]
          : typeof item.skip[visual.marketingType] === 'boolean'
          ? item.skip[visual.marketingType]
          : item.skip._
        );
      }
      if (item.annotations?.length) {
        item.annotationsMap = {};
        item.annotations.forEach((annotation) => {
          annotation.id = annotation.id || toCamelCase(annotation.exportKey);
          annotation.originalId = toCamelCase(annotation.originalKey);
          annotation.key = annotation.id;
          if (!annotation.label) {
            annotation.label = annotation.description || annotation.exportKey;
          }
          if (annotation.type === 'CHOICES') {
            annotation.choicesOriginalMap = annotation.choices.reduce(
              (agr, choice) => {
                agr[choice.originalValue] = choice;
                return agr;
              },
              {},
            );
            annotation.choicesMap = annotation.choices.reduce(
              (agr, choice) => {
                agr[choice.value] = choice;
                return agr;
              },
              {},
            );
          }
          if (isPlainObject(annotation.required)) {
            annotation.getIsRequired = (visual) => !!(
              annotation.required[visual.marketingKind]
              || annotation.required[visual.marketingType]
            );
          }
          item.annotationsMap[annotation.id] = annotation;
        });
      }
      return item;
    }),
  ),
);

Types.getAnnotationErrors = (visual, config, value, results, keys = []) => {
  const finalKeys = [...keys, config.key];
  const isSkipped = (
      config.getIsSkipped
    ? config.getIsSkipped(visual)
    : !!config.skip
  );
  if (!isSkipped) {
    const isRequired = (
        config.getIsRequired
      ? config.getIsRequired(visual)
      : !!config.required
    );
    if (config.type === 'SHAPE') {
      if (isRequired && !value?.length) {
        finalKeys.reduce(
          (agr, keysPart) => {
            agr.push(keysPart);
            results.keys[agr.join('.')] = true;
            results.count++;
            return agr;
          },
          [],
        );
      } else if (config.annotations?.length) {
        (value || []).forEach((subValue) => {
          config.annotations.forEach((subConfig) => {
            Types.getAnnotationErrors(
              visual,
              subConfig,
              subValue?.[subConfig.key],
              results,
              [
                ...finalKeys,
                subValue._id,
              ],
            );
          });
        });
      }
    } else if (config.type === 'CHOICES') {
      // const subValue = value?.[config.key];
      const subValue = value;
      if (isRequired && !subValue?.length) {
        finalKeys.reduce(
          (agr, keysPart) => {
            agr.push(keysPart);
            results.keys[agr.join('.')] = true;
            results.count++;
            return agr;
          },
          [],
        );
      }
    } else if (config.type === 'TEXT') {
      const subValue = value;
      if (isRequired && !subValue?.length) {
        finalKeys.reduce(
          (agr, keysPart) => {
            agr.push(keysPart);
            results.keys[agr.join('.')] = true;
            results.count++;
            return agr;
          },
          [],
        );
      }
    }
  }
};

Types.getAllAnnotationErrors = (
  value,
  visual,
  filter = annotations => annotations,
) => {
  const VISUAL_ANNOTATION_ITEMS = filter(
    Types.getVisualAnnotationItemsForMarketingType(
      visual.marketingType,
      visual.marketingKind,
    )
  );
  return VISUAL_ANNOTATION_ITEMS.reduce(
    (agr, config) => {
      Types.getAnnotationErrors(visual, config, value[config.key], agr);
      return agr;
    },
    { keys: {}, count: 0 },
  );
};

Types.getAnnotationsExport = (
  visual,
  filter = annotations => annotations,
) => {
  const finalResult = {};
  const VISUAL_ANNOTATION_ITEMS = filter(
    Types.getVisualAnnotationItemsForMarketingType(
      visual.marketingType,
      visual.marketingKind,
    )
  );
  /* eslint-disable dot-notation */
  finalResult['ID'] = TypesHelpers.getHashId(`${visual._id}`);
  finalResult['DataRow ID'] = TypesHelpers.getHashId(`ROW_ID_${visual._id}`);
  finalResult['Labeled Data'] = visual.image?.src || undefined;
  finalResult['Label'] = {
    objects: [],
    classifications: [],
  };
  Object.assign(finalResult, {
    /* eslint-disable quote-props */
    'Created By': 'uros@shopnosis.io',
    'Project Name': visual.name,
    'Created At': '2023-04-05T11:00:15.000Z',
    'Updated At': '2023-04-05T11:00:15.000Z',
    'Seconds to Label': 251.199,
    'Seconds to Review': 0,
    'Seconds to Create': 251.199,
    'External ID': 'Dijamant.jpg',
    'Global Key': null,
    'Agreement': -1,
    'Is Benchmark': 0,
    'Benchmark Agreement': -1,
    'Benchmark ID': null,
    'Dataset Name': visual.name,
    'Reviews': [],
    // eslint-disable-next-line max-len
    'View Label': 'https://editor.labelbox.com?project=clg238son0c7107zk0mys1fb1&label=clg3kpm5d092807zo2yjf3oqj',
    'Has Open Issues': 0,
    'Skipped': false,
    /* eslint-enable quote-props */
  });
  const areaPos = {};
  const areaPosSize = {
    x: Infinity,
    y: Infinity,
    x1: -Infinity,
    y1: -Infinity,
  };
  VISUAL_ANNOTATION_ITEMS.forEach((config) => {
    if (config.id === 'areaPos') {
      Object.assign(areaPos, {
        featureId: TypesHelpers.getHashId(`${ObjectId()}`),
        schemaId: config.exportSchemaId,
        title: config.label || config.description,
        value: config.exportKey,
      });
    } else if (config.type === 'SHAPE') {
      (visual.annotations?.[config.key] || []).forEach((value) => {
        const result = {
          featureId: TypesHelpers.getHashId(`${value._id}`),
          schemaId: config.exportSchemaId,
          title: config.label || config.description,
          value: config.exportKey,
        };
        if (config.shape === 'RECT') {
          result.bbox = {
            left: value.x,
            top: value.y,
            width: value.width,
            height: value.height,
          };
          areaPosSize.x = Math.min(areaPosSize.x, value.x);
          areaPosSize.y = Math.min(areaPosSize.y, value.y);
          areaPosSize.x1 = Math.max(
            areaPosSize.x1 || 0,
            value.x + value.width
          );
          areaPosSize.y1 = Math.max(
            areaPosSize.y1,
            value.y + value.height,
          );
        } else if (config.shape === 'POINT') {
          result.point = {
            x: value.x,
            y: value.y,
          };
          areaPosSize.x = Math.min(areaPosSize.x, value.x);
          areaPosSize.y = Math.min(areaPosSize.y, value.y);
          areaPosSize.x1 = Math.max(
            areaPosSize.x1,
            value.x
          );
          areaPosSize.y1 = Math.max(
            areaPosSize.y1,
            value.y,
          );
        }
        if (config.annotations?.length) {
          result.classifications = [];
          config.annotations.forEach((subConfig) => {
            const subValue = value?.[subConfig.key];
            if (subValue) {
              const subResult = {
                featureId: TypesHelpers.getHashId(`${ObjectId()}`),
                schemaId: subConfig.exportSchemaId,
                title: config.label || config.description,
                value: subConfig.exportKey,
              };
              if (subConfig.type === 'CHOICES') {
                const answers = (
                  Array.isArray(subValue) ? subValue : [subValue]
                ).reduce(
                  (agr, subChoiceValue) => {
                    const subChoiceItem = subConfig.choicesMap[
                      subChoiceValue
                    ];
                    if (subChoiceItem) {
                      agr.push({
                        featureId: TypesHelpers.getHashId(`${ObjectId()}`),
                        schemaId: subChoiceItem.exportSchemaId,
                        title: subChoiceItem.label,
                        value: subChoiceItem.value,
                      });
                    }
                    return agr;
                  },
                  [],
                );
                if (subConfig.multiple) {
                  subResult.answers = answers;
                } else {
                  // eslint-disable-next-line prefer-destructuring
                  subResult.answer = answers[0];
                }
              } else if (subConfig.type === 'TEXT') {
                if (subValue?.length) {
                  subResult.answer = subValue;
                }
              }
              result.classifications.push(subResult);
            }
          });
        }
        finalResult.Label.objects.push(result);
      });
    } else {
      const result = {
        featureId: TypesHelpers.getHashId(`${ObjectId()}`),
        schemaId: config.exportSchemaId,
        title: config.label || config.description,
        value: config.exportKey,
        scope: 'global',
      };
      if (config.getExportValue) {
        Object.assign(result, config.getExportValue(visual) || {});
      } else {
        const value = visual.annotations?.[config.key];
        if (value) {
          if (config.type === 'CHOICES') {
            const answers = (
              Array.isArray(value)
              ? value
              : value
              ? [value]
              : []
            ).reduce(
              (agr, choiceValue) => {
                const choiceItem = config.choicesMap[choiceValue];
                if (choiceItem) {
                  agr.push({
                    featureId: TypesHelpers.getHashId(`${ObjectId()}`),
                    schemaId: choiceItem.exportSchemaId,
                    title: choiceItem.label,
                    value: choiceItem.value,
                  });
                }
                return agr;
              },
              [],
            );
            if (config.multiple) {
              result.answers = answers;
            } else {
              // eslint-disable-next-line prefer-destructuring
              result.answer = answers[0];
            }
          } else if (config.type === 'TEXT') {
            if (value?.length) {
              result.answer = value;
            }
          }
        }
      }
      if (result.answer || result.answers?.length) {
        finalResult.Label.classifications.push(result);
      }
    }
  });
  if (areaPos?.featureId) {
    areaPos.bbox = {
      left: areaPosSize.x - 10,
      top: areaPosSize.y - 10,
      width: (areaPosSize.x1) + 20,
      height: (areaPosSize.y1) + 20,
    };
    finalResult.Label.objects.push(areaPos);
  }

  /* eslint-enable dot-notation */
  return finalResult;
};

// TODO Remove (testing only)
Types.getNewAnnotationChoices = (config, value) => {
  if (config.multiple) {
    if (Array.isArray(value)) {
      return value.reduce(
        (choicesAgr, choiceValue) => {
          const choiceConfig = config.choicesOriginalMap[choiceValue];
          if (choiceConfig) {
            choicesAgr.push(choiceConfig.value);
          }
          return choicesAgr;
        },
        [],
      );
    }
  } else {
    const choiceConfig = config.choicesOriginalMap[value];
    if (choiceConfig) {
      return choiceConfig.value;
    }
  }
  return undefined;
};

// TODO Remove (testing only)
Types.getNewAnnotationShape = (config, value) => {
  if (value) {
    const newValue = Types.VISUAL_ANNOTATION_SHAPE_MAP[config.shape]
      .keys
      .reduce(
        (agr, key) => {
          agr[key] = value[key];
          return agr;
        },
        { _id: value._id },
      );
    if (config.annotations?.length) {
      config.annotations.forEach((subItemConfig) => {
        const {
          key: subKey,
          originalId: subOriginalId,
        } = subItemConfig;
        // console.log(subKey, subOriginalId);
        const subValue = value[subOriginalId];
        if (typeof subValue !== 'undefined') {
          if (subItemConfig.type === 'CHOICES') {
            newValue[subKey] = Types.getNewAnnotationChoices(
              subItemConfig,
              subValue,
            );
          } else if (subValue?.length) {
            if (Array.isArray(subValue)) {
              if (subItemConfig.key === 'contentKeyVisual') {
                newValue[subKey] = subValue.map(subValueItem => (
                    subValueItem === 'ziivotinje_na_slici'
                  ? 'Životinje'
                  : subValueItem === 'ljudi'
                  ? 'Ljudi'
                  : subValueItem === 'proizvod_je_prikazan_u_neko_kontekstu'
                  ? 'Kontekst'
                  : subValueItem
                )).join(', ');
              } else {
                newValue[subKey] = subValue.join(', ');
              }
            } else {
              newValue[subKey] = subValue;
            }
          }
        }
      });
    }
    return newValue;
  }
  return undefined;
};

// TODO Remove (testing only)
Types.getNewAnnotationShapes = (config, value) => {
  if (config.multiple) {
    if (Array.isArray(value)) {
      return value.reduce(
        (agr, shapeValue) => {
          const newShapeValue = Types.getNewAnnotationShape(config, shapeValue);
          if (newShapeValue) {
            agr.push(newShapeValue);
          }
          return agr;
        },
        [],
      );
    }
    return undefined;
  }
  return Types.getNewAnnotationShape(config, value);
};

// TODO Remove (testing only)
Types.getNewAnnotations = (visual) => {
  let { annotations = {} } = visual;
  if (visual.annotationsOld) {
    annotations = visual.annotationsOld;
  }
  const newAnnotations = Types.VISUAL_ANNOTATION_ITEMS.reduce(
    (agr, itemConfig) => {
      if (!itemConfig.contentKey) {
        const { key, originalId } = itemConfig;
        // console.log(key, originalId);
        if (originalId) {
          const value = annotations[originalId];
          if (typeof value !== 'undefined') {
            if (itemConfig.type === 'SHAPE') {
              const newValue = Types.getNewAnnotationShapes(itemConfig, value);
              if (typeof newValue !== 'undefined') {
                agr[key] = newValue;
              }
            } else if (itemConfig.type === 'CHOICES') {
              const newValue = Types.getNewAnnotationChoices(itemConfig, value);
              if (typeof newValue !== 'undefined') {
                agr[key] = newValue;
              }
            } else if (value?.length) {
              const newValue = value;
              if (Array.isArray(newValue)) {
                agr[key] = newValue.join(', ');
              } else {
                agr[key] = newValue;
              }
            }
          }
        }
      }
      return agr;
    },
    {},
  );
  return newAnnotations;
};

Types.getVisualAnnotationItemsForMarketingType = (
  marketingType,
  marketingKind,
) => (
  Types.VISUAL_ANNOTATION_ITEMS.reduce(
    (agr, config) => {
      let used = true;
      if (config.getIsSkipped) {
        used = !config.getIsSkipped({
          marketingType,
          marketingKind,
        });
        // console.log('used:', config, marketingType, marketingKind, used);
      } else if (config.marketingType) {
        used = !!(
          !config.marketingType
          || config.marketingType?.[marketingType]
          || config.marketingType?._
        );
        // console.log(
        //   'used ised to it, if:', config,` marketingType, marketingKind, used`,
        // );
      }
      if (used) {
        const configUsed = { ...config };
        agr.push(configUsed);
        if (configUsed.annotations?.length) {
          configUsed.annotations = configUsed.annotations.reduce(
            (subAgr, subConfig) => {
              let subUsed = true;
              if (subConfig?.marketingType) {
                subUsed = !!(
                  !subConfig.marketingType
                  || subConfig.marketingType?.[marketingType]
                  || subConfig.marketingType
                );
              }
              if (subUsed) {
                const subConfigUsed = subConfig;
                subAgr.push(subConfigUsed);
              }
              return subAgr;
            },
            [],
          );
        }
      }
      return agr;
    },
    [],
  )
);

// console.log(JSON.stringify(Types.VISUAL_ANNOTATION_ITEMS, null, '  '));

export default Types;
