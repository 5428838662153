/* eslint-disable import/prefer-default-export */
import React from 'react';
import isFinite from 'lodash/isFinite';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import Admin from 'hive-admin';

import clickAnchor from '../helpers/clickAnchor';

import Types from '../common/modules/types';

import tests from '../helpers/tests';

const {
  isNotCreateActivity,
  viewerIsNotAdminNorContentManager,
  viewerIsNotAdminNorContentManagerWhichCanManagePrivateVisuals,
} = tests;

export function getValueForFieldDefault(value) {
  return (
    value && value.length
    ? value
    : undefined
  );
}

export function cloneItem(
  item,
  col = null,
  isAvailable = null,
  extras = {},
) {
  return [
    item[0],
    Object.assign(
      { ...item[1] },
      col ? { col: isFinite(col) ? { xs: 24, lg: col } : col } : {},
      isAvailable ? { isAvailable } : {},
      { ...extras },
    ),
  ];
}

export function createExpiresFields(
  name = '',
  supportsCustomDate = false,
  label = 'Expiration',
) {
  // const prefixExpires = `${prefix}expires`;
  const prefixExpires = `${name}`;
  const skipRelative = [
    'condition.testFieldValue',
    prefixExpires,
    '!==',
    'RELATIVE',
  ];
  const skipDate = [
    'condition.testFieldValue',
    prefixExpires,
    '!==',
    'DATE',
  ];
  const expiresChoices = [
    { label: 'Lasts Forever', value: 'NEVER' },
    { label: 'Expires After', value: 'RELATIVE' },
  ];
  if (supportsCustomDate) {
    expiresChoices.push({
      label: 'Expires On Date',
      value: 'DATE',
    });
  }
  const fields = [
    ['FieldRadio', {
      name: prefixExpires,
      label,
      block: true,
      initialValue: 'RELATIVE',
      choices: expiresChoices,
    }],
    ['FieldText', {
      name: `${prefixExpires}Duration`,
      label: null,
      type: 'number',
      align: 'right',
      initialValue: 1,
      rules: [['validation.isRequired']],
      prefix: 'After:',
      skip: [skipRelative],
      col: { xs: 24, md: 12 },
    }],
    ['FieldSelect', {
      name: `${prefixExpires}Period`,
      label: null,
      block: true,
      initialValue: 'YEAR',
      align: 'right',
      prefix: 'Period:',
      choices: [
        { label: 'Day', value: 'DAY' },
        { label: 'Week', value: 'WEEK' },
        { label: 'Month', value: 'MONTH' },
        { label: 'Year', value: 'YEAR' },
      ],
      getChoiceLabel: (choice, field, props) => {
        const count = props.form.getFieldValue(
          `${prefixExpires}Duration`,
        );
        if (count === 1) {
          return choice.label;
        }
        return `${choice.label}s`;
      },
      skip: [skipRelative],
      col: { xs: 24, md: 12 },
    }],
  ];
  if (supportsCustomDate) {
    fields.push(['FieldDatePicker', {
      name: `${prefixExpires}Date`,
      label: null,
      format: 'll',
      placeholder: 'Select Date',
      skip: [skipDate],
      prepareValueForInput: (
        value => (value ? moment(value) : null)
      ),
    }]);
  }
  return fields;
}

export const filterSort = ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    name: { name: 1 },
    date: { createdAt: -1 },
  },
  buildQueryDefault: 'date',
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP[filter.buildQueryDefault] })
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort',
    getInitialValue: (fieldConfig, props) => (
      fieldConfig.buildQueryDefault || props.buildQueryDefault
    ),
    prepareValueForInput: (value, props) => value || props.buildQueryDefault,
    choices: [{
      label: 'Sort by name',
      value: 'name',
    }, {
      label: 'Sort by date',
      value: 'date',
    }],
  }],
}];

export const columnName = {
  path: 'name',
  width: '100%',
  render: (record, column, props) => (
    <Link to={`${props.page.path}/${record._id}`}>
      {record.name}
    </Link>
  ),
};

export const filterCountry = ['FilterField', {
  id: 'country',
  label: null,
  section: 'top',
  // eslint-disable-next-line max-len
  buildQuery: (value, builder, filter) => (value && value.length && builder.add(
    filter.whereSide || 'where',
    {
      country: {
        IN: (
          Array.isArray(value)
          ? value
          : [value]
        ).map(inValue => (inValue === 'unavailable' ? null : inValue)),
      },
    },
  )),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'country',
    placeholder: 'Country',
    mode: 'multiple',
    allowClear: true,
    showArrow: true,
    optionFilterProp: 'children',
    choices: [
      { label: 'N/A', value: 'unavailable' },
      ...Types.COUNTRIES.map(({ name, iso3a2 }) => ({
        label: name,
        value: iso3a2,
      })),
    ],
  }],
}];

export const columnAnnotationValue = {
  path: 'annotationValue',
  title: 'Annotation Value',
};

export const columnActive = {
  path: 'active',
  title: <CheckOutlined style={{ color: '@primary' }} />, // TODO test
  width: '10px',
  align: 'center',
  render: (record) => (
    record.active
    ? <CheckOutlined />
    : <CloseOutlined style={{ opacity: 0.2 }} />
  ),
};

export const columnCountry = {
  path: 'country',
  title: 'Country',
  render: (record) => (
    Types.COUNTRIES_MAP[record.country]?.name
    || 'N/A'
  ),
};

export const columnCreatedAt = {
  path: 'createdAt',
  title: 'Created',
  render: record => moment(record.createdAt).format('ll'),
};

export const columnOrganization = {
  path: 'organization',
  title: 'Owner',
  render: (record, index, props) => (
    record.organization?.name
    ? (
        ['ADMIN'].includes(props.viewer?.role)
        ? (
            <Link to={`/organizations/${record.organization._id}`}>
              {record.organization.name}
            </Link>
          )
        : record.organization.name
      )
    : 'Public'
  ),
  isAvailable: isColumnAvailableOnlyForAdminOrContentManager,
}

export const columnCategory = {
  path: 'category',
  title: 'Category',
  render: (record, index, props) => (
    record.category?.name
    ? (
        ['ADMIN'].includes(props.viewer?.role)
        ? (
            <Link to={`/categories/${record.category._id}`}>
              {record.category.name}
            </Link>
          )
        : record.category.name
      )
    : '-'
  ),
};

export const columnKind = {
  path: 'kind',
  title: 'Type',
  render: record => Types.ITEM_TYPE_LABELS_MAP[record.kind],
  isAvailable: isColumnAvailableOnlyForAdmin,
}

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [['validation.isRequired']],
}];

export const fieldNameWithAnnotationValue = ['FieldText', {
  name: 'name',
  label: 'Name',
  rules: [['validation.isRequired']],
  prepareValueForForm: (value, props) => {
    if (props.activity === 'create') {
      const form = (
          props.forms
        ? props.forms[props.forms.length - 1]
        : props.form
      );
      value = value?.target?.value || value;
      if (form) {
        if (typeof value === 'string' && value?.length) {
          form.setFieldsValue({
            annotationValue: Types.getWordCharsExcludingAccents.toCamelCase(
              value,
            ),
          });
        } else {
          form.setFieldsValue({
            annotationValue: '',
          });
        }
      }
    }
    return value;
  },
}];

export const fieldAnnotationValue = ['FieldText', {
  name: 'annotationValue',
  label: 'Annotation Value',
  rules: [['validation.isRequired']],
}];

export const fieldSlug = ['FieldSlug', {
  name: 'slug',
  label: null,
  prefix: 'Slug:',
}];

export const actionDuplicate = ['Action', {
  name: 'duplicate',
  title: 'Duplicate',
  type: 'primary',
  icon: 'copy',
  ghost: false,
  onClick: (_props) => {},
}];

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search',
  }],
}];

export const filterActive = ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    active: { active: { EQ: true } },
    inactive: { active: { EQ: false } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : 'any'
  ),
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    prepareValueForInput: value => (value ? value : 'any'),
    initialValue: 'any',
    choices: [{
      label: 'Any Status',
      value: 'any',
    }, {
      label: 'Active',
      value: 'active',
    }, {
      label: 'Inactive',
      value: 'inactive',
    }],
  }],
}];

export const filterPagination = ['FilterPagination', {
  id: 'pagination',
  section: 'bottom',
  defaultPageSize: 50,
}];

export const filterPopulateOrganization = ['FilterHidden', {
  id: 'populate-organization',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { organization: true });
  },
}];

export const filterPopulateBrand = ['FilterHidden', {
  id: 'populate-brand',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { brand: true });
  },
}];

export const filterPopulateCategory = ['FilterHidden', {
  id: 'populate-category',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { category: true });
  },
}];

export const filterPopulateDepartment = ['FilterHidden', {
  id: 'populate-department',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { department: true });
  },
}];

export const filterLimitToPublic = ['FilterHidden', {
  id: 'limit-to-public',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('where', {
      organization: { EQ: null },
    });
  },
}];

export const filterLimitToStatusReport = ['FilterHidden', {
  id: 'limit-to-status-report',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('where', {
      status: 'REPORT',
    });
  },
}];

export const filterLimitToViewerOrganization = ['FilterHidden', {
  id: 'limit-to-viewer-organization',
  label: null,
  section: 'top',
  buildQuery: (value, builder, filter, params, props) => {
    if (props.pageProps?.viewer?.role === 'CUSTOMER') {
      builder.add('where', {
        organization: props.pageProps.viewer.organization,
      });
    }
  },
}];

export const filterLibraryExportCsv = ['FilterField', {
  id: 'library-export-csv',
  name: 'library-export-csv',
  label: null,
  section: 'top',
  col: 24,
  skip: [Admin.compileFromLibrary(viewerIsNotAdminNorContentManager)],
  buildQuery: () => {},
  // eslint-disable-next-line max-len
  propsFromPage: ({
    client,
    queryBuilder,
    restBaseServer,
    searchParams,
    path,
    viewer,
  }) => ({
    client,
    viewer,
    queryBuilder,
    restBaseServer,
    searchParams,
    pagePath: path,
  }),
  field: ['Action', {
    name: 'library-export-csv',
    title: 'Export To CSV',
    icon: 'download',
    type: 'default',
    style: { width: '100%' },
    onClick: (props) => clickAnchor({
      url: `${props.restBaseServer}${props.pagePath}/export`,
      query: {
        access_token: props.client.getAccessToken(),
        query: props.queryBuilder.compile(),
        timezone: moment.tz.guess(),
      },
    }),
  }],
}];

export const filterCompareSameMarketingKind = ['FilterHidden', {
  id: 'compare',
  label: null,
  section: 'top',
  buildQuery: (value, builder, _filter, _params, _props) => {
    if (value && value.length) {
      const compareIds = value.split(',');
      console.log('compareIds', compareIds);
      if (compareIds.length) {
        const compareId = compareIds[0];
        if (compareId) {
          builder.add('custom', { compareId });
        }
      }
    }
  },
}];

export const fieldActive = ['FieldSwitch', {
  name: 'active',
  label: null,
  inlineLabelBefore: 'Active',
  initialValue: true,
  col: { xs: 24, md: 12 },
}];

export const fieldKind = ['FieldRadio', {
  name: 'kind',
  label: 'Owner',
  choices: Types.ITEM_TYPE_LIST.map(({ id, label }) => ({
    label,
    value: id,
  })),
  initialValue: Types.ITEM_TYPE[0],
  getInitialValue: (field, props) => (
      props.data?.kind
    ? props.data.kind
    : props.viewer?.role === 'CUSTOMER'
    ? 'PRIVATE'
    : 'PUBLIC'
  ),
  block: true,
  hidden: [
    viewerIsNotAdminNorContentManagerWhichCanManagePrivateVisuals,
  ],
  disabled: [
    isNotCreateActivity,
  ],
  virtual: [
    isNotCreateActivity,
  ],
  skip: [
    viewerIsNotAdminNorContentManagerWhichCanManagePrivateVisuals,
  ],
}];

export const fieldOrganization = ['FieldConnectionSelect', {
  name: 'organization',
  label: null,
  getInitialValue: (field, props) => (
      props.data?.organization
    ? props.data.organization
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? props.viewer.organization
    : undefined
  ),
  prefix: 'Organization:',
  placeholder: 'Select the customers organization',
  url: '/organizations',
  searchPaths: ['name'],
  hidden: [
    viewerIsNotAdminNorContentManagerWhichCanManagePrivateVisuals,
  ],
  disabled: [
    isNotCreateActivity,
  ],
  virtual: [
    isNotCreateActivity,
  ],
  skip: [
    [
      'condition.testFieldValue',
      'kind',
      '!==',
      'PRIVATE',
    ],
  ],
}];

export const skipIfViewerCanNotManageVisuals = [
  'condition.not',
  ['condition.or', [
    tests.viewerIsAdminOrContentManagerWhichCanManageVisuals,
    tests.isViewerCustomerWhichCanManageOrganization,
    tests.isViewerCustomerWhichCanManageVisuals,
  ]],
];

export function isColumnAvailableOnlyForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

export function isColumnAvailableOnlyForAdminOrContentManager(props) {
  return ['ADMIN', 'CONTENT_MANAGER'].includes(props.viewer?.role);
}

export function isColumnAvailableOnlyForAdminWhenRoleIsCustomer(props) {
  if (!isColumnAvailableOnlyForAdmin(props)) {
    return false;
  }
  return props.searchParams.role === 'customer';
}

export function isColumnAvailableOnlyForAdminWhenRoleIsNotDefined(props) {
  if (!isColumnAvailableOnlyForAdmin(props)) {
    return false;
  }
  const { role } = props.searchParams;
  return !role || role === 'any';
}

export function isFilterAvailableOnlyForAdmin(props) {
  return props.viewer && props.viewer.role === 'ADMIN';
}

export function isFilterAvailableOnlyForAdminWhenRoleIsCustomer(props) {
  if (!isFilterAvailableOnlyForAdmin(props)) {
    return false;
  }
  const { role } = props.searchParams;
  return role === 'CUSTOMER';
}

export function isFilterAvailableOnlyForCustomer(props) {
  return props.viewer && props.viewer.role === 'CUSTOMER';
}
