import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
} from './fields';

const {
  viewerIsNotAdminNorContentManagerWhichCanManageVisuals,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Categories',
    icon: 'bars',
    path: '/categories',
    redirect: [['redirect.unauthorized']],
    skip: [viewerIsNotAdminNorContentManagerWhichCanManageVisuals],
    archiveConfig: {
      icon: 'bars',
      title: 'Categories',
      label: 'Categories',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/categories',
      backButtonPaths: ['/categories'],
      headerTitle: 'Create Category',
      // loadUrl: '/categories/:id/',
    },
    singleEditConfig: {
      backButtonPaths: ['/categories'],
    },
    fields: FIELDS_ALL,
  }])
).pages;
