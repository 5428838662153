import Admin from 'hive-admin';

import {
  cloneItem,
  filterSort,
  filterPagination,
  filterSearch,
} from '../common';

import tests from '../../helpers/tests';

import clickAnchor from '../../helpers/clickAnchor';

import Types from '../../common/modules/types';

const {
  viewerIsNotAdminNorContentManager,
} = tests;

export const filterSyncVideoSessions = ['FilterField', {
  id: 'sync-video-sessions',
  name: 'sync-video-sessions',
  label: null,
  section: 'top',
  col: 24,
  skip: [Admin.compileFromLibrary(viewerIsNotAdminNorContentManager)],
  buildQuery: () => {},
  // eslint-disable-next-line max-len
  propsFromPage: ({
    client,
    viewer,
  }) => ({
    client,
    viewer,
  }),
  field: ['ActionWithRequest', {
    name: 'sync-video-sessions',
    title: 'Sync Video Sessions',
    style: { width: '100%' },
    getRequestConfig: _props => ({
      url: 'videosessions/sync',
      method: 'POST',
    }),
    handleSuccess: (data, props) => window.location.reload(),
  }],
}];

export const filterStatus = ['FilterField', {
  id: 'status',
  name: 'status',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    value && value?.length && builder.add('where', { status: value });
  },
  field: ['FieldSelect', {
    name: 'status',
    prefix: 'Status:',
    prepareValueForInput: value => (value ? value : undefined),
    allowClear: true,
    choices: Types.VIDEO_SESSION_STATUS_ITEMS.map(({ id, label }) => ({
      label,
      value: id,
    })),
  }],
}];

export const filterAnnotationSessonUser = ['FilterField', {
  id: 'user',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    {
      'annotationsSession.user': {
        IN: Array.isArray(value) ? value : [value],
      },
    },
  ),
  skip: [Admin.compileFromLibrary(tests.viewerIsNotAdmin)],
  field: ['FieldConnectionSelect', {
    name: 'annotationsSession.user',
    prepareValueForInput: value => (value ? value : undefined),
    url: '/users',
    prefix: 'Annotator:',
    getExtraQueryConditions: () => [{
      role: { IN: ['ADMIN', 'CONTENT_MANAGER'] },
    }],
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterActionExport = ['FilterField', {
  id: 'export',
  name: 'export',
  label: null,
  section: 'top',
  buildQuery: () => {},
  propsFromPage: ({
    restBaseServer,
    client,
    match,
    queryBuilder,
  }) => ({
    restBaseServer,
    client,
    match,
    queryBuilder,
  }),
  field: ['Action', {
    name: 'export',
    title: 'Export To CSV',
    icon: 'download',
    ghost: true,
    style: { width: '100%' },
    onClick: (props) => clickAnchor({
      url: `${props.restBaseServer}/videosessionsexport/all`,
      query: {
        access_token: props.client.getAccessToken(),
        query: JSON.stringify({
          where: props.queryBuilder.build()?.where,
        }),
      },
    }),
  }],
}];

export const filterPopulateAnnotationsSessionUser = ['FilterHidden', {
  id: 'populate-annotationsSession-user',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { 'annotationsSession.user': true });
  },
  skip: [tests.viewerIsNotAdminNorContentManager],
}];

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  ...[
    [filterSearch, 12],
    [[filterSort[0], { ...filterSort[1], buildQueryDefault: 'date' }], 12],
    [filterStatus, 24],
    [filterAnnotationSessonUser, 24],
    [filterSyncVideoSessions, 24],
    [filterActionExport, 24],
  ].map((config) => cloneItem(...config)),
  filterPopulateAnnotationsSessionUser,
  filterPagination,
];
