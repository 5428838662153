import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
} from './fields';

const {
  viewerIsNotAdminNorContentManagerWhichCanManageVisuals,
} = tests;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Retailers',
    icon: 'shop',
    path: '/retailers',
    redirect: [['redirect.unauthorized']],
    skip: [viewerIsNotAdminNorContentManagerWhichCanManageVisuals],
    archiveConfig: {
      icon: 'shop',
      title: 'Retailers',
      label: 'Retailers',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/retailers',
      backButtonPaths: ['/retailers'],
      headerTitle: 'Create Retailer',
      // loadUrl: '/retailers/:id/',
    },
    singleEditConfig: {
      backButtonPaths: ['/retailers'],
    },
    fields: FIELDS_ALL,
  }])
).pages;
