import React from 'react';
import styled from 'styled-components';

import { PictureOutlined } from '@ant-design/icons';
import AntdPopover from 'antd/lib/popover';

import { Link } from 'react-router-dom';

import Types from '../../common/modules/types';

import {
  // columnName,
  columnCreatedAt,
  columnOrganization,
  columnCategory,
  columnCountry,
} from '../common';

export const columnVisualId = {
  path: 'visualId',
  title: 'ID',
  render: (record, column, props) => (
    <Link to={`${props.page.path}/${record._id}`}>
      <code>
        {record.visualId}
      </code>
    </Link>
  ),
}

export const columnName = {
  path: 'name',
  title: 'Name',
  width: '100%',
  render: (record, column, props) => (
    <Link to={`${props.page.path}/${record._id}`}>
      {record.name}
    </Link>
  ),
};

// export const columnName = {
//   path: 'name',
//   width: '100%',
//   render: (record, column, props) => (
//     <Link
//       to={`${props.page.path}/${record._id}/${
//         record.status === 'MEW'
//         ? 'content'
//         : record.status === 'PROCESSING'
//         ? 'processing'
//         : record.status === 'REPORT'
//         ? 'report'
//         : ''
//       }`}
//     >
//       {record.name}
//     </Link>
//   ),
// };

const PopoverImage = styled.div`
  width: 280px;
  height: 280px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popover = styled(AntdPopover)`

`;

export const columnImage = {
  path: 'image',
  title: null,
  render: (record) => (
    record.image?.src
    ? (
        <Popover
          content={(
            <PopoverImage
              style={{ backgroundImage: `url(${record.image?.src})` }}
            />
          )}
        >
          <div
            style={{
              padding: '10px',
              margin: '-10px',
            }}
          >
            <PictureOutlined />
          </div>
        </Popover>
      )
    : (
        <div
          style={{
            padding: '10px',
            margin: '-10px',
            opacity: 0.3,
          }}
        >
          <PictureOutlined />
        </div>
      )
  ),
};

export const columnStatus = {
  path: 'status',
  title: 'Status',
  render: (record, column, props) => (
    <>
      {Types.VISUAL_STATUS_LABELS_MAP[record.status]}
      {
        (
          props.viewer
          && props.viewer?.role !== 'CUSTOMER'
          && record.status === 'PROCESSING'
          && record.annotationsSession
        )
        ? (
            <>
              {' by '}
              {
                record.annotationsSession?.user?.name
                ? (
                    <Link
                      to={`/users/${
                        record.annotationsSession.user._id
                      }`}
                    >
                      {record.annotationsSession?.user?.name}
                    </Link>
                  )
                : 'someone'
              }
            </>
          )
        : null
      }
    </>
  ),
};

export const columnMarketingType = {
  path: 'marketingType',
  title: 'Type',
  render: record => (
    Types.VISUAL_MARKETING_TYPE_MAP[record.marketingType]?.label
  ),
};

export const columnStoppingPower = {
  path: 'visibilityScore',
  title: 'Stopping Power',
  align: 'right',
  render: record => (
    record.status === 'REPORT'
    ? record.visibilityScore
    : '-'
  ),
}

export const columnEngagement = {
  path: 'engagementScore',
  title: 'Engagement',
  align: 'right',
  render: record => (
    record.status === 'REPORT'
    ? record.engagementScore
    : '-'
  ),
}

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  columnImage,
  columnVisualId,
  columnName,
  columnStatus,
  columnCountry,
  columnMarketingType,
  columnCreatedAt,
  columnStoppingPower,
  columnEngagement,
  columnCategory,
  columnOrganization,
];
