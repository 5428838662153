import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import styled, { css } from 'styled-components';
import padStart from 'lodash/padStart';
import useHotkeys from '@reecelucas/react-use-hotkeys';

// import {
//   DeleteOutlined,
//   PlusOutlined,
// } from '@ant-design/icons';

import mongoose from 'mongoose/browser';

import AntdSlider from 'antd/lib/slider';
import AntdButton from 'antd/lib/button';
import AntdRadio from 'antd/lib/radio';
import AntdInput from 'antd/lib/input';

import Admin from 'hive-admin';

import { Tooltip } from '../Popover';

import {
  viewerIsAnnotating,
} from '../../routes/visuals/fields';

import Types from '../../common/modules/types';

const { Types: { ObjectId } } = mongoose;

// function createFakeAnnotations(duration = 0, step = 0.01) {
//   return new Array(20).fill(null).map((_, index) => ({
//     _id: index,
//     type: 'PRODUCT',
//     name: `Product ${index}`,
//     meta: {},
//     events: (
//       new Array(Math.floor(Math.random() * 4) + 1)
//       .fill(null)
//       .map((__, eventIndex) => {
//         const randomTime = Math.random() * duration;
//         const time = step * Math.floor(randomTime / step);
//         return {
//           _id: eventIndex,
//           type: 'CLICK',
//           name: `Click ${eventIndex}`,
//           meta: {},
//           // time: Math.floor((Math.random() * duration) * 100) / 100,
//           time,
//         };
//       })
//       .sort((a, b) => a.time - b.time)
//       .map((event, eventIndex) => {
//         event.name = `Click ${eventIndex}`;
//         return event;
//       })
//     ),
//   }));
// }

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid ${props => props.theme.less.borderColor};
  background: white;
`;

const Video = styled.video`
  width: 100%;
  position: sticky;
  height: auto;
`;

const AnnotationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.less.borderColor};
  border-top: 0px;
  border-radius: ${props => props.theme.less.borderRadius};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

const AnnotationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.less.borderColor};
  }
  &[data-is-control-annotation="true"] {
    border-right: 1px solid ${props => props.theme.less.borderColor};
    border-left: 1px solid ${props => props.theme.less.borderColor};
    border-bottom: 0px;
  }
  &:not([data-is-control-annotation="true"]) .ant-slider-handle {
    display: none;
  }
`;

const AnnotationLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 220px;
  max-width: 220px;
  border-right: 1px solid ${props => props.theme.less.borderColor};
  padding: 10px;
`;

const AnnotationControlWrapper = styled(AnnotationLabelWrapper)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 220px;
  max-width: 220px;
`;

const AnnotationControlButtonGroup = styled(AntdButton.Group)`

`;

const AnnotationControlButton = styled(AntdButton)`
  ${
    props => (
        props.$danger
      ? css`color: ${({ theme }) => theme.less.errorColor};`
      : undefined
    )
  }
`;

const AnnotationControlRadioButtonGroup = styled(AntdRadio.Group)`

`;

const AnnotationControlRadioButton = styled(AntdRadio.Button)`
  ${
    props => (
        props.$danger
      ? css`color: ${({ theme }) => theme.less.errorColor};`
      : undefined
    )
  }
`;

const AnnotationTimelineWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  /* background: red; */
  padding: 10px 20px;
  border-right: 1px solid ${props => props.theme.less.borderColor};
`;

const AnnotationRightWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 100px;
  padding: 10px;
  gap: 6px;
  align-items: center;
  justify-content: center;
`;

// const AnnotationEventActionAdd = styled(PlusOutlined)`
//
// `;

// const AnnotationEventActionDelete = styled(DeleteOutlined)`
//
// `;

const Slider = styled(AntdSlider)`
  width: 100%;
  margin: 0px;
  .ant-slider-step {
    display: none;
  }
  .ant-slider-mark {
    position: relative;
    top: 0px;
  }
  .ant-slider-mark-text-active {
    opacity: 1;
    margin-top: 0px;
  }
`;

const SliderMarkWrapper = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;
  // top: 50%;
  // left: 50%;
`;

const SliderMarkIcon = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  transform: translate(-50%, -50%);
  top: 2px;
  // left: -50%;
  // top: -50%;
  background: ${props => props.theme.less.primaryColor};
  border-radius: 50%;
  &[data-active="true"] {
    width: 24px;
    height: 24px;
  }
`;

function SliderMark({ active, annotation, event, onClick }) {
  return (
    <SliderMarkWrapper onClick={onClick}>
      <Tooltip
        title={
          Types
          ?.VIDEO_SESSION_ANNOTATION_TYPE_MAP[annotation.type]
          ?.EVENT_TYPE_MAP[event.type]
          ?.label
        }
        content={
          [
            'PRODUCT_ADD_TO_CART',
            'PRODUCT_REMOVE_FROM_CART',
          ].includes(event.type)
          ? `Quantity: ${event?.meta?.quantity}`
          : null
        }
      >
        <SliderMarkIcon data-active={active} />
      </Tooltip>
    </SliderMarkWrapper>
  );
}

function convertToClockTime(currentSeconds, fps = 30) {
  const minutes = Math.floor(currentSeconds / 60); // Get the total minutes
  const seconds = Math.floor(currentSeconds % 60); // Get the remaining seconds
  const milliseconds = Math.floor((currentSeconds % 1) * 1000); // Get the milliseconds from the decimal part
  const frames = Math.round(currentSeconds / (1 / fps)); // Get the total frames
  return {
    minutes,
    seconds,
    milliseconds,
    frames,
  };
}

function Timeline({
  annotationId,
  annotation,
  duration,
  currentTime,
  events = [],
  isActive,
  onChange,
  onEventClick,
}) {
  const sliderRef = useRef();
  // const [isDragging, setIsDragging] = useState(false);
  const marks = useMemo(
    () => {
      const eventsSorted = events.sort((a, b) => a.time - b.time);
      return eventsSorted.reduce(
        (agr, event) => {
          agr[event.time] = {
            // label: event.name,
            label: (
              <SliderMark
                active={isActive}
                annotation={annotation}
                event={event}
                onClick={() => onEventClick(annotationId, event._id)}
              />
            ),
          };
          return agr;
        },
        {},
      );
    },
    [annotation, events, isActive, annotationId, onEventClick],
  );
  // useEffect(
  //   () => {
  //     console.log(sliderRef.current);
  //   },
  //   [],
  // );
  return (
    <Slider
      ref={sliderRef}
      min={0}
      max={duration}
      step={0.01}
      // disabled={events.length > 0}
      // step={events.length ? null : 0.01}
      value={currentTime}
      onChange={onChange}
      tooltipVisible={false}
      marks={marks}
      // onMouseDown={() => console.log('dragging') || setIsDragging(true)}
      // onMouseUp={() => setIsDragging(false)}
    />
  );
}

function AnnotationItem(props) {
  const {
    annotation,
    duration,
    currentTime,
    currentClockTime,
    onTimelineChange,
    // activeEventAnnotationId,
    // activeEventId,
    setActiveAnnotationAndEventIds,
    setAddingOrEditingAnnotationId,
    deleteActiveAnnotation,
    onAnnotationsChange,
    annotations,
  } = props;
  // const isActive = activeEventAnnotationId === annotation._id;
  // const activeEvent = useMemo(
  //   () => (
  //     isActive
  //     && typeof activeEventId === 'string'
  //     && annotation.events.find(({ _id }) => _id === activeEventId)
  //   ),
  //   [isActive, activeEventId, annotation],
  // );
  return (
    <AnnotationWrapper key={annotation._id}>
      <AnnotationLabelWrapper
        onClick={() => setAddingOrEditingAnnotationId(annotation._id)}
      >
        {
          annotation.type === 'PRODUCT'
          ? annotation.meta.productName
          : annotation.type
        }
      </AnnotationLabelWrapper>
      <AnnotationTimelineWrapper>
        <Timeline
          annotationId={annotation?._id}
          annotation={annotation}
          duration={duration}
          currentTime={currentTime}
          onChange={onTimelineChange}
          currentClockTime={currentClockTime}
          events={annotation.events}
          onEventClick={(annotationId, eventId) => (
            setActiveAnnotationAndEventIds({
              annotationId,
              eventId,
            })
          )}
        />
      </AnnotationTimelineWrapper>
      <AnnotationRightWrapper>
        <AntdButton
          type="link"
          icon="plus"
          size="small"
          onClick={() => setActiveAnnotationAndEventIds({
            annotationId: annotation._id,
            eventId: false,
          })}
        />
        <AntdButton
          type="link"
          icon="copy"
          size="small"
          onClick={() => {
            const { type, meta } = annotation;
            const newAnnotations = [...annotations, {
              _id: `${ObjectId()}`,
              type,
              meta: {
                ...(meta || {}),
                productName: `Item ${annotations.length + 1}`,
              },
              events: [],
            }];
            onAnnotationsChange(newAnnotations);
          }}
        />
        <AntdButton
          type="link"
          icon="delete"
          size="small"
          // disabled={!activeEvent}
          // style={{ color: activeEvent ? 'red' : '#ddd' }}
          style={{ color: 'red' }}
          onClick={() => deleteActiveAnnotation(annotation._id)}
        />
        {/* <AnnotationActionAdd />
        <AnnotationActionDelete /> */}
      </AnnotationRightWrapper>
    </AnnotationWrapper>
  )
}

export default function VideoSession(props) {
  const {
    value: annotations,
    onChange: onAnnotationsChange,
  } = props;
  const videoRef = useRef();
  const [videoFps, setVideoFps] = useState(30);
  const [videoSpeed, setVideoSpeed] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [annotationDefaults, setAnnotationDefaults] = useState();
  const [eventDefaults, setEventDefaults] = useState();
  const [
    addingOrEditingAnnotationId,
    setAddingOrEditingAnnotationId,
  ] = useState(false);
  const editingAnnotation = useMemo(
    () => (
      typeof addingOrEditingAnnotationId === 'string'
      ? annotations.find(({ _id }) => _id === addingOrEditingAnnotationId)
      : undefined
    ),
    [annotations, addingOrEditingAnnotationId],
  );
  const [
    {
      annotationId: activeEventAnnotationId,
      eventId: activeEventId,
    },
    setActiveAnnotationAndEventIds,
  ] = useState({
    annotationId: false,
    eventId: false,
  });
  const canAddNewAnnotation = (
    viewerIsAnnotating(props)
    && props.data?.status === 'PROCESSING'
  );
  const addingOrEditingEvent = !!activeEventAnnotationId;
  const {
    activeEventAnnotation,
    activeEvent,
  } = useMemo(
    () => {
      if (typeof activeEventAnnotationId === 'string') {
        const annotation = annotations.find(
          ({ _id }) => _id === activeEventAnnotationId
        );
        if (annotation) {
          if (typeof activeEventId === 'string') {
            const event = annotation.events.find(
              ({ _id }) => _id === activeEventId
            );
            if (event) {
              return {
                activeEventAnnotation: annotation,
                activeEvent: event,
              };
            }
          }
          return {
            activeEventAnnotation: annotation,
            activeEvent: undefined,
          };
        }
      }
      return {
        activeEventAnnotation: undefined,
        activeEvent: undefined,
      };
    },
    [annotations, activeEventAnnotationId, activeEventId],
  );
  const currentClockTime = useMemo(
    () => convertToClockTime(currentTime, videoFps),
    [currentTime, videoFps],
  );
  const isPlaying = (
    !videoRef.current
    ? false
    : !!(
        videoRef.current.currentTime > 0
        && !videoRef.current.paused
        && !videoRef.current.ended
        && videoRef.current.readyState > 2
      )
  );
  useEffect(
    () => {
      const { current: video } = videoRef;
      const timeUpdateInterval = setInterval(
        () => {
          if (video.currentTime !== currentTime) {
            setCurrentTime(video.currentTime);
          }
        },
        500,
      );
      const onLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
        // Assuming browser provides 'video.webkitDecodedFrameCount' (works in Chrome)
        const totalFrames = video.webkitDecodedFrameCount || (
          videoRef.current.duration * 30
        ); // Fallback to 30 fps if not available
        const newVideoFps = Math.round(totalFrames / video.duration);
        if (videoFps !== newVideoFps) {
          setVideoFps(newVideoFps);
        }
        // setAnnotations(createFakeAnnotations(videoRef.current.duration));
      };
      video.addEventListener('loadedmetadata', onLoadedMetadata);
      return () => {
        clearInterval(timeUpdateInterval);
        video.removeEventListener('loadedmetadata', onLoadedMetadata);
      }
    },
    [currentTime, videoFps],
  );
  const onTimelineChange = useCallback(
    (value) => {
      if (Number.isFinite(value)) {
        setCurrentTime(value);
        videoRef.current.currentTime = value;
      }
    },
    [],
  );

  useHotkeys('" "', () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  });

  useHotkeys('n', () => {
    setAddingOrEditingAnnotationId(true);
    setAnnotationDefaults({ type: 'PRODUCT' });
  });

  useHotkeys('ArrowLeft', () => {
    videoRef.current.pause();
    videoRef.current.currentTime -= (5 / videoFps);
    setCurrentTime(videoRef.current.currentTime);
  });

  useHotkeys('ArrowRight', () => {
    videoRef.current.pause();
    videoRef.current.currentTime += (5 / videoFps);
    setCurrentTime(videoRef.current.currentTime);
  });

  useHotkeys('Enter', () => {
    const action = (
      document.querySelector('[data-action="add-or-save-annotation"]')
      || document.querySelector('[data-action="add-or-save-event"]')
    );
    if (action) {
      try {
        action.click();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('cannot click:', error, action);
      }
    }
  });

  useHotkeys('d', () => {
    if (annotations.length > 0) {
      const { type, meta } = annotations[annotations.length - 1];
      const newAnnotations = [...annotations, {
        _id: `${ObjectId()}`,
        type,
        meta: {
          ...(meta || {}),
          productName: `Item ${annotations.length + 1}`,
        },
        events: [],
      }];
      onAnnotationsChange(newAnnotations);
    }
  });

  [[0, 0.5], [1], [2], [4]].forEach(([shortcut, speed]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useHotkeys(`${shortcut}`, () => {
      setVideoSpeed(speed || shortcut);
      videoRef.current.playbackRate = speed || shortcut;
    });
  });

  Types.VIDEO_SESSION_ANNOTATION_TYPE_MAP.PRODUCT.EVENT_TYPE_ITEMS.forEach(
    (eventConfig) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useHotkeys(eventConfig.shortcut, () => {
        if (annotations?.length > 0) {
          setEventDefaults({
            type: eventConfig.id,
            time: currentTime,
            frame: currentClockTime.frames,
          });
          setActiveAnnotationAndEventIds({
            annotationId: annotations[annotations.length - 1]?._id,
            eventId: false,
          });
        }
      });
    },
  );

  const annotationAddEditAction = useMemo(
    () => Admin.compileFromLibrary(['ActionWithFormModal', {
      name: 'add-edit-annotation',
      label: null,
      title: 'New Item',
      icon: 'plus',
      type: 'primary',
      style: { marginTop: '16px' },
      // skip: [viewerIsNotAdmin],
      modalControlled: true,
      getModalProps: (localProps) => ({
        title: (
          localProps.modalMode === 'edit'
          ? 'Edit Item'
          : 'Add Item'
        ),
        autoFocusButton: true,
        destroyOnClose: true,
      }),
      fields: [
        ['FieldSelect', {
          name: 'type',
          label: 'Type',
          autoFocus: true,
          isDisabled: localProps => localProps.modalMode !== 'add',
          choices: (
            Types.VIDEO_SESSION_ANNOTATION_TYPE_ITEMS.map(({ id, label }) => ({
              value: id,
              label,
            }))
          ),
          initialValue: Types.VIDEO_SESSION_ANNOTATION_TYPE[0],
        }],
        // ['FieldText', {
        //   label: 'Name',
        //   placeholder: 'Product Name',
        //   name: 'meta.productName',
        //   col: 12,
        // }],
        // ['FieldText', {
        //   label: 'Price',
        //   name: 'meta.productPrice',
        //   align: 'right',
        //   type: 'number',
        //   // initialValue: 1,
        //   col: 12,
        // }],
        ['FieldRadio', {
          label: 'Promotion',
          name: 'meta.productPromotion',
          block: true,
          initialValue: false,
          choices: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }],
          col: 24,
        }],
        ['FieldSelect', {
          name: 'meta.productCategory',
          label: 'Category',
          allowClear: true,
          showSearch: true,
          choices: Types.VIDEO_SESSION_ANNOTATION_PRODUCT_CATEGORY_ITEMS.map(
            ({ id, label }) => ({
              value: id,
              label,
            }),
          ),
        }],
        ['FieldSelect', {
          name: 'meta.productBrand',
          label: 'Brand',
          allowClear: true,
          showSearch: true,
          choices: Types.VIDEO_SESSION_ANNOTATION_PRODUCT_BRAND_ITEMS.map(
            ({ id, label }) => ({
              value: id,
              label,
            }),
          ),
        }],
        ['FieldSelect', {
          name: 'meta.productLocation',
          label: 'Location',
          allowClear: true,
          showSearch: true,
          choices: Types.VIDEO_SESSION_ANNOTATION_PRODUCT_LOCATION_ITEMS.map(
            ({ id, label }) => ({
              value: id,
              label,
            }),
          ),
        }],
        ['FieldSelect', {
          name: 'meta.productPosition',
          label: 'Position',
          allowClear: true,
          showSearch: true,
          choices: Types.VIDEO_SESSION_ANNOTATION_PRODUCT_POSITION_ITEMS.map(
            ({ id, label }) => ({
              value: id,
              label,
            }),
          ),
        }],
      ],
      actions: [
        ['Action', {
          name: 'delete',
          title: 'Delete',
          type: 'danger',
          onClick: (localProps) => {
            localProps.deleteActiveAnnotation(localProps.data._id);
            localProps.closeModal();
          },
          skip: [localProps => localProps.modalMode === 'add'],
        }],
        ['Action', {
          name: 'add-or-save-annotation',
          title: 'Add',
          getActionTitle: (localProps) => (
            localProps.modalMode === 'edit'
            ? 'Save'
            : 'Add'
          ),
          onClick: (localProps) => {
            const values = localProps.form.getFieldsValue();
            if (localProps.modalMode === 'edit') {
              localProps.saveAnnotation(
                localProps.editingAnnotation._id,
                values,
              );
            } else {
              localProps.addAnnotation(values);
            }
            localProps.closeModal();
          },
        }],
      ],
    }]),
    [],
  );

  const eventAddEditAction = useMemo(
    () => Admin.compileFromLibrary(['ActionWithFormModal', {
      name: 'add-edit-event',
      label: null,
      title: 'New Event',
      icon: 'plus',
      type: 'primary',
      getSkip: () => true,
      // skip: [viewerIsNotAdmin],
      modalControlled: true,
      getModalProps: (localProps) => ({
        title: (
          localProps.modalMode === 'edit'
          ? 'Edit Event'
          : 'Add Event'
        ),
        autoFocusButton: true,
        destroyOnClose: true,
      }),
      fields: [
        ['FieldSelect', {
          name: 'type',
          label: 'Type',
          isDisabled: localProps => localProps.modalMode !== 'add',
          renderChoices: (field, localProps) => (
            Types
            .VIDEO_SESSION_ANNOTATION_TYPE_MAP[
              localProps.activeEventAnnotation?.type
            ]
            ?.EVENT_TYPE_ITEMS
            ?.map?.(({ id, label }) => localProps.renderChoice(
              {
                value: id,
                label,
              },
              field,
              localProps,
            ))
          ) || [],
          getInitialValue: (fieldConfig, localProps) => (
            localProps?.data?.type
            || (
              Types
              .VIDEO_SESSION_ANNOTATION_TYPE_MAP[
                localProps?.activeEventAnnotation?.type
              ]
              ?.EVENT_TYPE
              ?.[0]
            )
          ),
        }],
        ['FieldText', {
          type: 'number',
          label: 'Frame',
          name: 'frame',
          getInitialValue: (fieldConfig, localProps) => (
            typeof localProps?.data?.frame === 'undefined'
            ? localProps.currentClockTime.frames
            : localProps?.data?.frame
          ),
        }],
        ['FieldText', {
          label: 'Quantity',
          placeholder: 'Quantity',
          name: 'meta.quantity',
          type: 'number',
          initialValue: 1,
          skip: [
            // eslint-disable-next-line max-len
            localProps => !(
              ['PRODUCT_ADD_TO_CART', 'PRODUCT_REMOVE_FROM_CART'].includes(
                localProps?.form?.getFieldValue?.('type')
                || localProps.data?.type
              )
            ),
          ],
        }],
      ],
      actions: [
        ['Action', {
          name: 'delete',
          title: 'Delete',
          type: 'danger',
          onClick: (localProps) => {
            localProps.deleteEvent(
              localProps.activeEventAnnotationId,
              localProps.data._id,
              localProps,
            );
            localProps.closeModal();
          },
          skip: [localProps => localProps.modalMode === 'add'],
        }],
        ['Action', {
          name: 'add-or-save-event',
          title: 'Add',
          getActionTitle: (localProps) => (
            localProps.modalMode === 'edit'
            ? 'Save'
            : 'Add'
          ),
          onClick: (localProps) => {
            const values = localProps.form.getFieldsValue();
            if (localProps.modalMode === 'edit') {
              localProps.saveEvent(
                localProps.activeEventAnnotationId,
                {
                  ...localProps.data,
                  ...values,
                  meta: {
                    ...(localProps.meta || {}),
                    ...(values.meta || {}),
                  },
                },
                localProps,
              );
            } else {
              localProps.addEvent(
                localProps.activeEventAnnotationId,
                values,
                localProps,
              );
            }
            localProps.closeModal();
          },
        }],
      ],
    }]),
    [],
  );

  return (
    <Wrapper>
      {/* <AntdAnchor> */}
        <VideoWrapper>
          <Video
            ref={videoRef}
            src={props.data.src}
            width="100%"
            // controls
          />
          <AnnotationWrapper data-is-control-annotation="true">
            <AnnotationControlWrapper>
              <AnnotationControlButtonGroup>
                {
                  isPlaying
                  ? (
                      <AnnotationControlButton
                        icon="pause"
                        size="small"
                        onClick={() => videoRef.current.pause()}
                      />
                    )
                  : (
                      <AnnotationControlButton
                        icon="caret-right"
                        size="small"
                        onClick={() => videoRef.current.play()}
                      />
                    )
                }
              </AnnotationControlButtonGroup>
              <AnnotationControlButtonGroup>
                <AnnotationControlButton
                  icon="step-backward"
                  size="small"
                  onClick={() => {
                    videoRef.current.pause();
                    videoRef.current.currentTime -= (5 / videoFps);
                    setCurrentTime(videoRef.current.currentTime);
                  }}
                />
                <AnnotationControlButton
                  icon="step-forward"
                  size="small"
                  onClick={() => {
                    videoRef.current.pause();
                    videoRef.current.currentTime += (5 / videoFps);
                    setCurrentTime(videoRef.current.currentTime);
                  }}
                />
              </AnnotationControlButtonGroup>
              <AnnotationControlRadioButtonGroup
                size="small"
                defaultValue={1}
                value={videoSpeed}
                onChange={(event) => {
                  const speed = parseFloat(event.target.value);
                  setVideoSpeed(speed);
                  videoRef.current.playbackRate = speed;
                }}
              >
                {
                  [0.5, 1, 2, 4].map(
                    (speed) => (
                      <AnnotationControlRadioButton
                        key={speed}
                        value={speed}
                        size="small"
                      >
                        {`${speed}`}
                      </AnnotationControlRadioButton>
                    ),
                    [],
                  )
                }
              </AnnotationControlRadioButtonGroup>
            </AnnotationControlWrapper>
            <AnnotationTimelineWrapper>
              <Timeline
                duration={duration}
                currentTime={currentTime}
                onChange={onTimelineChange}
                currentClockTime={currentClockTime}
              />
            </AnnotationTimelineWrapper>
            <AnnotationRightWrapper
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '130%',
                textAlign: 'center',
              }}
            >
              <Tooltip
                destroyTooltipOnHide
                title={(
                  <AntdInput
                    type="number"
                    placeholder="Jump to frame"
                    onChange={(event) => {
                      const newFrames = parseInt(event.target.value, 10);
                      if (Number.isFinite(newFrames)) {
                        videoRef.current.pause();
                        const newTime = newFrames / videoFps;
                        setCurrentTime(newTime);
                        videoRef.current.currentTime = newTime;
                      }
                    }}
                  />
                )}
              >
                <span>
                  {`${
                    padStart(`${currentClockTime.minutes}`, 2, '0')
                  }:${
                    padStart(`${currentClockTime.seconds}`, 2, '0')
                  }:${
                    padStart((currentClockTime.frames % (videoFps)), 2, '0')
                  }`}
                </span>
                <br />
                <span
                  style={{
                    fontSize: '80%',
                  }}
                >
                  {`f ${currentClockTime.frames}`}
                </span>
              </Tooltip>
            </AnnotationRightWrapper>
          </AnnotationWrapper>
        </VideoWrapper>
      {/* </AntdAnchor> */}
      {
        annotations?.length > 0
        ? (
          <AnnotationsWrapper>
            {
              annotations.map((annotation) => (
                <AnnotationItem
                  key={annotation._id}
                  annotations={annotations}
                  onAnnotationsChange={onAnnotationsChange}
                  duration={duration}
                  currentTime={currentTime}
                  currentClockTime={currentClockTime}
                  onTimelineChange={onTimelineChange}
                  annotation={annotation}
                  activeEventAnnotationId={activeEventAnnotationId}
                  activeEventId={activeEventId}
                  setActiveAnnotationAndEventIds={
                    setActiveAnnotationAndEventIds
                  }
                  setAddingOrEditingAnnotationId={
                    setAddingOrEditingAnnotationId
                  }
                  deleteActiveAnnotation={(annotationId) => {
                    onAnnotationsChange(
                      annotations.filter(({ _id }) => _id !== annotationId),
                    )
                  }}
                />
              ))
            }
          </AnnotationsWrapper>
          )
        : null
      }
      {
        canAddNewAnnotation
        ? annotationAddEditAction.render({
            client: props.client,
            data: editingAnnotation || annotationDefaults,
            parentData: props.data,
            datas: [props.data],
            forms: [props.form],
            modalVisible: !!addingOrEditingAnnotationId,
            modalMode: (
              typeof addingOrEditingAnnotationId === 'string'
              ? 'edit'
              : 'add'
            ),
            currentClockTime,
            editingAnnotation,
            closeModal: () => setAddingOrEditingAnnotationId(false),
            addAnnotation: (data) => {
              const { type, meta } = data;
              const newAnnotations = [...annotations, {
                _id: `${ObjectId()}`,
                type,
                meta: {
                  ...(meta || {}),
                  productName: (
                    (meta || {}).productName || `Item ${annotations.length + 1}`
                  ),
                },
                events: [],
              }];
              onAnnotationsChange(newAnnotations);
            },
            saveAnnotation: (annotationId, data) => {
              const { meta, events } = data;
              const newAnnotations = annotations.map((annotation, i) => {
                if (annotation._id === annotationId) {
                  return {
                    ...annotation,
                    meta: {
                      ...(meta || annotation.meta || {}),
                      productName: (
                        (meta || {}).productName || `Item ${i + 1}`
                      ),
                    },
                    events: events || annotation.events,
                  };
                }
                return annotation;
              });
              onAnnotationsChange(newAnnotations);
            },
            deleteActiveAnnotation: (annotationId) => {
              onAnnotationsChange(
                annotations.filter(({ _id }) => _id !== annotationId),
              )
            },
            onModalVisibleChange: (visible) => {
              document.activeElement?.blur?.();
              setAddingOrEditingAnnotationId(visible);
              if (!visible && annotationDefaults) {
                setAnnotationDefaults(undefined);
              }
            },
          })
        : null
      }
      {eventAddEditAction.render({
        client: props.client,
        data: activeEvent || eventDefaults,
        parentData: props.data,
        datas: [props.data],
        forms: [props.form],
        modalVisible: !!addingOrEditingEvent,
        modalMode: (
            activeEvent
          ? 'edit'
          : 'add'
        ),
        activeEventAnnotationId,
        activeEventId,
        activeEventAnnotation,
        activeEvent,
        currentTime,
        currentClockTime,
        videoFps,
        closeModal: () => setActiveAnnotationAndEventIds({
          annotationId: false,
          eventId: false,
        }),
        addEvent: (annotationId, data, localProps) => {
          const { type, meta } = data;
          const newEvent = {
            _id: `${ObjectId()}`,
            type,
            meta: meta || {},
            time: localProps.currentTime,
            frame: localProps.currentClockTime.frames,
          };
          const newAnnotations = annotations.map((annotation) => {
            if (annotation._id === annotationId) {
              return {
                ...annotation,
                meta: annotation.meta,
                events: [
                  ...annotation.events,
                  newEvent,
                ],
              };
            }
            return annotation;
          });
          onAnnotationsChange(newAnnotations);
        },
        saveEvent: (annotationId, data, localProps) => {
          const { _id, type, meta, frame } = data;
          const newEvents = (
            localProps.activeEventAnnotation.events.map((event) => {
              if (event._id === _id) {
                return {
                  ...event,
                  frame: frame || event.frame,
                  time: frame / localProps.videoFps,
                  type,
                  meta: meta || event.meta,
                };
              }
              return event;
            })
          );
          const newAnnotations = annotations.map((annotation) => {
            if (annotation._id === annotationId) {
              return {
                ...annotation,
                events: newEvents,
              };
            }
            return annotation;
          });
          onAnnotationsChange(newAnnotations);
        },
        deleteEvent: (annotationId, eventId, _localProps) => {
          const newAnnotations = annotations.map((annotation) => {
            if (annotation._id === annotationId) {
              return {
                ...annotation,
                events: annotation.events.filter(
                  ({ _id }) => _id !== eventId
                ),
              };
            }
            return annotation;
          });
          onAnnotationsChange(newAnnotations);
        },
        onModalVisibleChange: (visible) => {
          document.activeElement?.blur?.();
          if (!visible) {
            setActiveAnnotationAndEventIds({
              annotationId: false,
              eventId: false,
            });
            if (!visible && eventDefaults) {
              setEventDefaults(undefined);
            }
          }
        },
      })}
    </Wrapper>
  );
}

Admin.addToLibrary(
  'FieldVideoSession',
  (config = {}) => Admin.compileFromLibrary(['FieldReact', {
    name: 'annotations',
    label: null,
    renderContent: (props) => (
      <VideoSession {...props} />
    ),
    virtual: [() => false],
    ...config,
  }]),
);

// export default ['FieldReact', {
//   name: 'annotations',
//   label: null,
//   renderContent: (props) => (
//     <VideoSession {...props} />
//   ),
// }];
