import './helpers/fixInfiniteScroller';

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components/macro';

import { stringify as stringifyQuery } from 'querystring';

import { Link } from 'react-router-dom';

import Query from 'hive-admin/src/components/Query';

import Admin from './components/Admin';

import './helpers/library';

import 'hive-admin/src/components/FieldWYSIWYG';

import './antd.less';
import './admin.less';

import './components/PageArchiveTable.less';

import './components/FieldSlug';
import './components/ActionSave';
import './components/FieldTable';
import './components/FieldColorPicker';
import './components/FieldAnnotations';
import './components/FieldVideoSession';

import './components/PageSingleVisual';

import SignupPage from './routes/signup';
import DashboardPage from './routes/dashboard';
import UserPages from './routes/users';
import OrganizationPages, { MyOrganizationPage } from './routes/organizations';
import BrandPages from './routes/brands';
import RetailerPages from './routes/retailers';
import VisualPages, { TrackerPages } from './routes/visuals';
import DepartmentPages from './routes/departments';
import CategoryPages from './routes/categories';
import VideoSessionPages from './routes/videosessions';
import SettingsGroup from './routes/settings';

import DevelopmentGroup from './routes/development';

import theme from './theme';

import { renderLogo, renderSidebarLogo } from './components/Logo';

import Types from './common/modules/types';

const {
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_PUBLIC_URL = '',
} = process.env;

const { SERVER_URL } = Types;

const base = window.ADMIN_BASE = (
    window.location.pathname.indexOf('/admin') === 0
  ? `${REACT_APP_PUBLIC_URL}/`
  : ''
);
const restBase = `${REACT_APP_HTTP_PORT}` !== '80'
? `${REACT_APP_API_PATH}`
: `${SERVER_URL}${REACT_APP_API_PATH}`;

const admin = Admin.create({
  base,
  restBase,
  restBaseServer: `${SERVER_URL}${REACT_APP_API_PATH}`,
  restBaseRoot: `${SERVER_URL}${REACT_APP_API_PATH}`,
  titlePrefix: 'Shopnosis | ',
  sidebarProps: { renderLogo: renderSidebarLogo },
  passwordSetSuccessRedirectPath: '/login',
  accountActivationSuccessRedirectPath: '/login',
  viewerUrl: '/users/me',
  validateViewer: async (viewer, props) => {
    await Promise.all([
      (async () => {
        if (viewer) {
          await Promise.all([
            (
              props.client
              .get(`/settings/general?${stringifyQuery({
                includeInternalStores: true,
              })}`)
              .then((res) => {
                viewer.settings = res.data;
              })
            ),
            (
              viewer.role === 'CUSTOMER'
              ? (
                  props.client
                  .get('/organizations/me')
                  .then((res) => {
                    viewer.organizationData = res.data;
                    viewer.organizationData.dashboardPowerBiReportsMap = (
                      viewer.organizationData.dashboardPowerBiReports || []
                    ).reduce(
                      (agr, report) => {
                        agr[report._id] = report;
                        return report;
                      },
                      {},
                    );
                  })
                )
              : Promise.resolve()
            ),
          ]);
        }
      })(),
    ]);
    if (viewer.role === 'CUSTOMER') {
      try {
        window.pendo.initialize({
          visitor: {
            id: viewer._id,
            email: viewer.email,
            full_name: viewer.name,
            role: viewer.role,
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error on pendo.initialize', error);
      }
    }
    return viewer;
  },
  structure: [
    ['PageLogin', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized', '/']],
      renderAfterForm: props => (
        <>
          <p className="after-form" style={{ marginBottom: '0.3em' }}>
            Lost your password? Click
            {' '}
            <Link to={props.passwordResetPath}>
              here
            </Link>
            {' '}
            to set a new one.
          </p>
          <Query
            client={props.client}
            url="/users/signup"
          >
            {({ data }) => (
              data?.data?.open
              ? (
                  <p className="after-form">
                    If you dont yet have an account, click
                    {' '}
                    <Link to="/signup">
                      here
                    </Link>
                    {' '}
                    to sign up instead.
                  </p>
                )
              : null
            )}
          </Query>
        </>
      ),
    }],
    ['Page', {
      hidden: true,
      path: '/',
      exact: true,
      redirect: [
        ['redirect.unauthorized'],
        ['redirect.always', '/users/me'],
      ],
    }],
    [SignupPage[0], {
      renderBeforeForm: renderLogo,
      ...SignupPage[1],
    }],
    ['PagePasswordReset', {
      renderBeforeForm: renderLogo,
      redirect: [['redirect.authorized']],
    }],
    ['PagePasswordSet', { renderBeforeForm: renderLogo }],
    ['PageAccountActivation', {
      renderBeforeForm: renderLogo,
      accountActivationSuccessRedirectPath: '/',
    }],
    ...VisualPages,
    ...TrackerPages,
    DashboardPage,
    MyOrganizationPage,
    ...OrganizationPages,
    ...UserPages,
    ...RetailerPages,
    ...DepartmentPages,
    ...CategoryPages,
    ...BrandPages,
    ...VideoSessionPages,
    SettingsGroup,
    DevelopmentGroup,
    ['Page404', {
      redirect: [
        ['redirect.unauthorized'],
        ['redirect.always', '/'],
      ],
    }],
  ],
});

ReactDOM.render(
  (
    <ThemeProvider theme={theme}>
      {admin}
    </ThemeProvider>
  ),
  document.getElementById('root'),
);
