import React from 'react';

import { Link } from 'react-router-dom';

import {
  columnName,
  columnCreatedAt,
} from '../common';

import Types from '../../common/modules/types';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  columnName,
  {
    path: 'status',
    title: 'Status',
    render: (record, column, props) => (
      Types.VIDEO_SESSION_STATUS_LABELS_MAP[record.status]
    ),
  },
  {
    path: 'annotationsSession.user.name',
    title: 'Annotator',
    render: (record) => {
      if (record.annotationsSession?.user?.name) {
        return (
          <Link
            to={`/users/${record.annotationsSession.user._id}`}
          >
            {record.annotationsSession.user.name}
          </Link>
        );
      }
      return '';
    },
  },
  columnCreatedAt,
];
