import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import Types from '../../common/modules/types';

import { Title, Subtitle, Body } from './Text';
import { Space, Column } from './Layout';
import BarScore from './BarScore';
import StarScore from './StarScore';

import {
  ImageProvider,
  FieldAnnotationsModal,
} from '../FieldAnnotations';

const RemoveSteps = createGlobalStyle`
  .ant-steps-horizontal {
    display: none !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 40px auto 0;
  margin-top: 0px;

  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const MainScore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 30px;

  flex-direction: column;
  @media (min-width: 1380px) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  background: hsla(0, 0%, 0%, 0.03);
  border-radius: 16px;
  /* padding: 20px; */
  overflow: hidden;

  width: 100%;
  @media (min-width: 1380px) {
    width: 50%;
  }
`;

const Image = styled(InnerImageZoom)`
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;

  img.iiz__img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;

const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  width: 100%;
  padding: 20px;
  @media (min-width: 1380px) {
    width: 50%;
    padding: 50px;
  }
`;

const Metrics = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
`;

const OrderedList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Report = ({ data, _viewer, isTablet }) => {
  const {
    title,
    image,
    // imageDisplay,
    // brand,
    // marketingType,
    marketingTypeLabel,
    improvementSuggestions,
  } = data;

  return (
    <Wrapper>
      <RemoveSteps />
      <MainScore>
        <ImageWrapper>
          <Image src={image?.src} alt="main" hideHint />
        </ImageWrapper>
        <ContentWrapper>
          <Column>
            <Title>{title}</Title>
            <Subtitle>
              {/* {Types.VISUAL_MARKETING_TYPE_MAP[marketingType].label} */}
              {marketingTypeLabel}
            </Subtitle>
          </Column>
          {Object.values(Types.REPORT_VALUES_MAP)
            .filter((item) => item.type === 'SCORE' && !item.getIsSkipped(
              data.marketingType
            ))
            .map((score) => {
              const value = data[score.id] || 0;
              const valueDesc = Types.SCORE_VALUE_DESCRIPTIONS_MAP[value];
              return (
                <BarScore
                  key={score.key}
                  title={score.label}
                  scale={5}
                  score={value}
                  subtitle={valueDesc.description}
                  description={valueDesc.note}
                  badge={valueDesc.badge}
                />
              )
            })}
            <ImageProvider
              // form={props.form}
              data={data}
              value={data.annotations}
              // onChange={props.onChange}
              // wrapperStyle={props.wrapperStyle}
              // canGenerate={props.viewer?.role === 'ADMIN'}
              isSmall={isTablet}
              buttonProps={{
                type: 'primary',
                style: {
                  textAlign: 'center',
                  height: '60px',
                },
              }}
              readOnly
              hideDescriptions
              hideAnnotations={[
                ...(
                  // eslint-disable-next-line max-len
                  data.marketingType === Types.VISUAL_MARKETING_TYPE_MAP.SHELF_READY_PACKAGING
                  ? [
                      'areaPos',
                    ]
                  : []
                ),
                ...[
                  'srpFrontArea',
                  'srpPresentation',
                  'srpCondition',
                  'srpExperience',
                  'srpColor',
                  'srpShape',
                  'srpContrast',
                  'areaPOS',
                  'areaProduct',
                  'contentDiscount',
                  'contentDisplaySize',
                  'areaProduct',
                  'contentDisplayLuxury',
                  'contentProductCount',
                  'contentDisplayBoxes',
                  'contentMainColor',
                  'contentDisplayMaterial',
                ],
              ]}
            >
              <FieldAnnotationsModal />
            </ImageProvider>
        </ContentWrapper>
      </MainScore>
      <Column>
        <Title as="h2">Details</Title>
        <Subtitle as="h5">
          Comparison with &quot;Best in Class&quot;
        </Subtitle>
      </Column>
      <Metrics>
        {
          Types.REPORT_VALUES_LIST
          .filter((item) => item.type === 'METRIC' && !item.getIsSkipped(
            data.marketingType
          ))
          .map(metric => (
            <StarScore
              key={metric.key}
              title={metric.label}
              description={metric.description}
              stars={5}
              score={data[metric.key] || 0}
            />
          ))
        }
      </Metrics>
      {improvementSuggestions.length > 0 ? (
        <>
          <Column>
            <Title as="h2">Recommendation For Improvement</Title>
            <Subtitle as="h5">Powered by AI</Subtitle>
          </Column>
          <OrderedList>
            {improvementSuggestions.map(item => (
              <li key={item.issue} style={{ fontWeight: 700, fontSize: 16 }}>
                <Title as="h3">{item.issue}</Title>
                <Space h={8} />
                <Body>{item.suggestion}</Body>
              </li>
            ))}
          </OrderedList>
        </>
      ) : null}
      <Space h={50} />
    </Wrapper>
  );
};

export default Report;
